
.App {
  text-align:left;
}

/* navebar */
.navbar {
  background-color: black !important;
}
.nav-link{
  color: aliceblue !important;
}
.navbar-brand{
  color: aliceblue !important;
}
.navbar-nav{
  margin-left: auto;
}
.smallimg{
  height: 260px;
  width: auto !important;
  border-radius: 5px !important;;
}
.bf{
  margin-left: 160px;
}


h1{
  font-size: 22px !important;
}

.bs{
  display: flex !important;
  flex-wrap: wrap;
  max-width: 1000px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-top: 20px !important;
  border-radius: 5px !important;
  padding: 20px !important;
  margin-bottom: 20px !important;
}
.bo{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.os{box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
margin-top: 20px !important;
padding: 20px;}


.bigimg{
  height: 390px;
  width: auto;
  border-radius: 5px;

}

input,select,.ant-picker{
  box-shadow: none !important;
  margin-top: 10px !important;
  width: 100%;
  height: 35px !important;
}
h2{
  font-size: 30px;
  text-align: center;
  font-weight: bold ;
  padding: 10px;
}
h5{
  text-align: center;
}

.landing{
  background-color: black !important;
  height: 100vh;
  padding-top: 130px;
}
